'use client';

import { type ReactNode, createContext, useRef, useContext } from 'react';
import { type StoreApi, useStore } from 'zustand';
import { createRootStore } from '@/stores/root-store';
import { RootStore } from '@/stores/root-store.types';

export const RootStoreContext = createContext<StoreApi<RootStore> | null>(null);

export interface RootStoreProviderProps {
  children: ReactNode;
  session: any;
}

export const RootStoreProvider = ({
  children,
  session
}: RootStoreProviderProps) => {
  const storeRef = useRef<StoreApi<RootStore>>();
  if (!storeRef.current) {
    storeRef.current = createRootStore();
  }
  storeRef.current.getInitialState().session = JSON.parse(session);

  // console.log('Store with session', storeRef.current.getInitialState());
  return (
    <RootStoreContext.Provider value={storeRef.current}>
      {children}
    </RootStoreContext.Provider>
  );
};

export const useRootStore = <T,>(selector: (store: RootStore) => T): T => {
  const rootStoreContext = useContext(RootStoreContext);

  if (!rootStoreContext) {
    throw new Error(`useRootStore must be use within RootStoreProvider`);
  }

  return useStore(rootStoreContext, selector);
};
