import { StoreApi } from "zustand";
import { RootStore } from "@/stores/root-store.types";

export const createHydratateSlice = (
  set: StoreApi<RootStore>["setState"],
  get: StoreApi<RootStore>["getState"],
  ...rest: StoreApi<RootStore>[]
) => ({
  hasHydrated: false,
  setHasHydrated: (state: boolean) => {
    set({
      hasHydrated: state,
    });
  },
});
