import { TPaymentStatus, TReservationStatus } from '@/utils/reservations';
import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { TConsumer } from './consumer-api';
import { TOrganizationFAQ } from './organization-api';

export type TOrganizationService = {
  id: string;
  name: string;
  price: number;
  reservationPct: number;
  active: boolean;
  description: string | null;
  isExtension: boolean;
  maxConsumers: number;
  minConsumers: number;
};

export type getServiceType = {
  total: number;
  limit: number;
  offset: number;
  page: number;
  hasNextPage: boolean;
  results: TOrganizationService[];
};

type Slot = {
  from: string; // "HH:MM"
  to: string; // "HH:MM"
  status: string;
  statusDetail?: string;
};

export type availableDateType = {
  date: string; // "DD-MM-YYYY"
  slots: Slot[];
};

type PaymentType = {
  id: string;
  name: string;
  description: string;
};

type PaymentStatus = {
  id: string;
  name: TPaymentStatus;
  description: string;
};

export type Payment = {
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  id: string;
  reference: string;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  amount: number;
  observations: string | null;
  paidAt: string | null;
};

export type TReservation = {
  id: string;
  endTime: string;
  name: string;
  startTime: string;
  status: TReservationStatus;
  enabled: boolean;
  order: number;
};
interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface Service {
  id: string;
  name: string;
  description: string;
  price: number;
  nextSessionDuration: number;
  remainingTime: number;
  completedPct: number;
  reservationPct: number;
  allowPayment: 'all' | 'admin';
  visibility: 'all' | 'admin';
  canUpdateReservations: 'all' | 'admin';
  maxConsumers: number;
  minConsumers: number;
  minAge: number;
  maxAge: number;
}
interface TSaleStatus {
  name: 'paymentPending' | 'paymentComplete' | 'canceled';
  observation?: string;
}
type TSaleItem = {
  active: boolean;
  createdAt: string;
  createdBy: string | null;
  description: string;
  id: string;
  minPreReservationTime: number;
  name: string;
  price: number;
  quantity: number;
  reservationPct: number;
  service: Service;
  updatedAt: string;
  updatedBy: string | null;
};
export type myServicesData = {
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  id: string;
  user: User;
  service: Service;
  validUntil: string | null;
  participants: TConsumer[];
  reservations: TReservation[];
  payments: Payment[];
  amountPending: number;
  totalAmount: number;
  organization: TOrganizationFAQ;
  acceptedTos: boolean;
  status: TSaleStatus;
  saleItems: TSaleItem[];
};

type getMyServicesType = {
  total: number;
  limit: number;
  offset: number;
  page: number;
  hasNextPage: boolean;
  results: myServicesData[];
};

export type TCategoryServices = {
  sport: {
    id: string;
    name: string;
  };
  services: TOrganizationService[];
};

export class ServicesApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getOrganization(
    organizationId: string
  ): Promise<TOrganizationService[]> {
    const response: ApiResponse<getServiceType> = await this.api.apisauce.get(
      'service?organization=' + organizationId
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }

  async getMyService(): Promise<myServicesData[]> {
    await this.api.getRequestConfig();
    const response: ApiResponse<getMyServicesType> =
      await this.api.apisauce.get(`sale?sortBy=createdAt&sortDirection=DESC`);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }
  async getSale(saleId: string): Promise<myServicesData | undefined> {
    await this.api.getRequestConfig();
    const response: ApiResponse<myServicesData> = await this.api.apisauce.get(
      `sale/${saleId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getAvailability({
    serviceId,
    participants,
    month,
    year
  }: {
    serviceId: string;
    participants: number;
    month: number;
    year: number;
  }): Promise<availableDateType[] | undefined> {
    const response: ApiResponse<availableDateType[]> =
      await this.api.apisauce.get(
        `service/schedule?service=${serviceId}&consumerQty=${participants}&month=${month}&year=${year}`
      );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async getOrganizationCategoriesWithServices(
    organizationId: string
  ): Promise<TCategoryServices[]> {
    const response: ApiResponse<TCategoryServices[]> =
      await this.api.apisauce.get(
        `service/sport?organization=${organizationId}`
      );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data || [];
  }
}
