import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { TAmountType } from '@/components/modals/payment-option-modal/payment-option-modal.types';

export class PaymentApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getRequestPayment(
    saleId: string,
    amountType?: TAmountType
  ): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `payment/request`,
      { saleId, amountType }
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
