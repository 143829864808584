import { ApiResponse } from 'apisauce';
import { Api } from './api';

export class ReservationApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async changeReservationStatus(
    status: string,
    idReservation: string,
    date?: number
  ): Promise<any> {
    await this.api.getRequestConfig();
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `reservation/${status}/${idReservation}`,
      date ? { date } : undefined
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
