import { RootStore } from '@/stores/root-store.types';
import { StoreApi } from 'zustand';

export const createSessionSlice = (
  set: StoreApi<RootStore>['setState'],
  get: StoreApi<RootStore>['getState'],
  ...rest: StoreApi<RootStore>[]
) => ({
  isInsuranceDataComplete: false,
  session: undefined,
  inIframe: false,

  setIsInsuranceDataComplete: (state: boolean) => {
    set({
      isInsuranceDataComplete: state
    });
  },
  setSession: (state: any) => {
    set({
      session: state
    });
  },
  setInIframe: (state: boolean) => {
    set({
      inIframe: state
    });
  }
});
