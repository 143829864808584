import { ApiResponse } from 'apisauce';
import { Api } from './api';

interface TIdentificationType {
  createdBy: string;
  updatedBy: string;
  createdAt: number;
  updatedAt: number;
  active: boolean;
  id: string;
  name: string;
  description: string;
  country: string;
  regex: string;
}

export class IdentificationApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getIdentificationTypes(): Promise<TIdentificationType[] | undefined> {
    await this.api.getRequestConfig();
    const response: ApiResponse<TIdentificationType[]> =
      await this.api.apisauce.get(`identification-type`);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
