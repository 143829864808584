import { RootStore } from '@/stores/root-store.types';
import { StoreApi } from 'zustand';

export const createInsuranceModalSlice = (
  set: StoreApi<RootStore>['setState'],
  get: StoreApi<RootStore>['getState'],
  ...rest: StoreApi<RootStore>[]
) => ({
  isModalOpen: false,
  lastClosed: null,
  openModal: () => set({ isModalOpen: true }),
  closeModal: () => {
    const timestamp = Date.now();
    localStorage.setItem('modalLastClosed', timestamp.toString());
    set({ isModalOpen: false, lastClosed: timestamp });
  },
  checkModalStatus: () => {
    const lastClosed = localStorage.getItem('modalLastClosed');
    if (lastClosed) {
      const now = Date.now();
      const diff = now - parseInt(lastClosed, 10);
      if (diff > 5000) {
        set({ isModalOpen: true });
      }
    } else {
      set({ isModalOpen: true });
    }
  }
});
