import { Mixpanel } from '@/utils/mixPanel';
import { useUser } from '@auth0/nextjs-auth0/client';
import { Callback, Dict, RequestOptions } from 'mixpanel-browser';
import { useEffect } from 'react';
import useGetUser from './queries/user/useGetUser';

export default function useMixPanel() {
  const { user: loggedUser } = useUser();
  const { data: user } = useGetUser(loggedUser?.email);

  useEffect(() => {
    if (loggedUser?.email) {
      Mixpanel.identify(loggedUser.email);
      Mixpanel.people.set({ $email: loggedUser.email });

      if (user) {
        const { firstName, lastName } = user;
        Mixpanel.people.set({ $first_name: firstName, $last_name: lastName });
      }
    }
  }, [loggedUser, user]);

  const track = (
    event_name: string,
    properties?: Dict,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback
  ) => {
    Mixpanel.track(event_name, properties, optionsOrCallback, callback);
  };

  return { track };
}
