import { ApiResponse } from 'apisauce';
import { Api } from './api';

export type SalePostPayload = {
  email?: string;
  firstName?: string;
  lastName?: string;
  identification?: { type?: string; number?: string };
  phone?: string;
  service?: string;
  validUntil?: string;
  paymentType?: string;
  firstReservationStartTime?: number;
  consumers?: Array<TConsumer | string>;
  acceptedTos?: boolean;
};

export type TConsumer = {
  firstName?: string;
  lastName?: string;
  birthdate?: string;
  identification?: { type?: string; number?: string };
  weight?: number;
  height?: number;
  id?: string;
  phone?: string;
  address?: string;
  location?: string;
  postalCode?: string;
  email?: string;
  isOwnUser?: boolean;
};

export type GetSaleOutDto = {
  id: string;
  validUntil: Date;
  user: {
    id: string;
  };
  service: {
    id: string;
    name: string;
    participants: number;
    nextSessionDuration: number; // minutes
    remainingTime: number; // minutes
  };
};

export class SaleApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async newBooking(payload: SalePostPayload): Promise<any> {
    await this.api.getRequestConfig();
    const response: ApiResponse<GetSaleOutDto> = await this.api.apisauce.post(
      `sale`,
      JSON.stringify(payload)
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async updateSale({
    saleId,
    payload
  }: {
    saleId: string;
    payload: SalePostPayload;
  }): Promise<any> {
    await this.api.getRequestConfig();
    const response: ApiResponse<GetSaleOutDto> = await this.api.apisauce.patch(
      `sale/${saleId}`,
      JSON.stringify(payload)
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
